<template>
  <AppCardHeader v-if="mq.current !== 'xs'">
    <template #left>
      <slot name="left">
        <AppBackButton
          v-if="layoutPageStepper && layoutPageStepper.activeStepIdx > 0 && !backButtonHidden"
          class="back-button"
          name="Go Back"
          :analytics-name="analyticsName ? analyticsName + '-back' : null"
          icon="<"
          @click="back"
        />
      </slot>
    </template>

    <h1 v-if="title">
      {{ title }}
    </h1>

    <template v-if="$slots.right" #right>
      <slot name="right"></slot>
    </template>
  </AppCardHeader>
</template>

<script>
import { inject, onUnmounted, onMounted } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import { AppCardHeader, AppBackButton, useMediaQuery } from '@oen.web.vue2/ui'

import { useI18nStore, useEnvStore } from '@galileo/stores'

export default {
  name: 'XeStepperCardHeader',
  components: {
    AppCardHeader,
    AppBackButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    analyticsName: {
      type: String,
      default: 'card-header-back',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
    emitBack: {
      type: Boolean,
      default: false,
    },
    backButtonHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const mq = useMediaQuery()
    const layoutPageStepper = inject('layoutPageStepper')

    onMounted(() => {
      layoutPageStepper.title = props.title
      layoutPageStepper.navBack = back
    })

    onUnmounted(() => {
      layoutPageStepper.title = ''
    })

    const back = () => {
      if (props.emitBack) {
        emit('back')
      } else {
        router.go(-1)
      }
    }

    return {
      mq,
      layoutPageStepper,
      back,
      $t,
    }
  },
}
</script>

<style scoped>
h1 {
  @apply text-lg;
  @apply font-medium;
}
.back-button {
  max-width: 48px;
}
</style>
