import API from '@galileo/api/launchpad'
import APIHandler, { setHeader } from '@galileo/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  ({ ipHeader }) => {
    setHeader('X-Forwarded-For', ipHeader)

    return {
      method: 'GET',
      url: `system/user-location`,
    }
  },
  (response) => response.data,
  (error) => error
)
