import axios from 'axios'


const getExternalIP = () => {
    const apiUrl = 'https://api.ipify.org/?format=json';
  
    return new Promise((resolve, reject) => {
      axios.get(apiUrl)
        .then(response => {
          const ip = response.data.ip;
          resolve(ip);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  
  export { getExternalIP }