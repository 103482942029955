<template>
  <AppCard>
    <template #header>
      <XeStepperCardHeader
        :title="$t('PageSendMoneyVerifyIdentityEKYC.Title').value"
        class="summary-header"
        analytics-name="xe-send-money-summary"
      >
      </XeStepperCardHeader>
    </template>
    <AppSpinnerBig :loading="isLoading" inline />
    <template v-if="!isLoading">
      <h2 class="text-left text-lg font-bold mb-4">
        {{ $t('PageSendMoneyVerifyIdentity.MakeSure').value }}
      </h2>
      <ol class="ol mb-6 leading-5" v-html="$t('PageSendMoneyVerifyIdentity.Guidelines').value" />

      <div v-if="biometricConsentRequired" class="flex flex-row">
        <AppInputCheckbox
          :value="hasUserConsented"
          @input="(newValue) => (hasUserConsented = newValue)"
          class="biometric-consent -ml-3"
        >
        </AppInputCheckbox>
        <p
          class="text-xs text-gray-text leading-4"
          v-html="
            $t('PageSendMoneyVerifyIdentity.BiometricConsent', {
              verificationPlatform,
              privacyPolicyLink,
            }).value
          "
        />
      </div>
    </template>

    <template #footer>
      <AppCardFooter>
        <AppButton
          :disabled="isContinueDisabled"
          analytics-name="send-verify-identity-onfido-continue"
          @click="startVerification"
        >
          {{ $t('PageSendMoneyVerifyIdentity.ButtonContinue').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useI18n } from 'vue-composable'
import { useRouter } from '@galileo/composables/useRouter'
import {
  SEGMENT_EVENTS,
  SEGMENT_IDENTITY_VERIFICATION_PLATFORMS,
} from '@galileo/constants/segmentAnalytics'

import { STEPS } from '@galileo/constants/sendMoneyFlow.const'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import getIsBiometricConsentRequired from '@galileo/api/launchpad/system/user-location/get'
import { getExternalIP } from '@galileo/utilities/getExternalIP.utility'

import {
  AppButton,
  AppCard,
  AppCardFooter,
  AppCardHeader,
  AppInputCheckbox,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

import {
  useResourcesStore,
  useI18nStore,
  useSendMoneyStore,
  useAnalyticsStore,
  useAuthStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyVerifyIdentity',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppInputCheckbox,
    XeStepperCardHeader,
    AppSpinnerBig,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const isLoading = ref(false)

    const pageTitle = $t('PageSendMoneyVerifyIdentity.Title')

    const resourcesStore = useResourcesStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()

    const privacyPolicyLink = computed(() => {
      return resourcesStore.getPrivacyPolicyUrl
    })

    const verificationPlatform = sendMoneyStore.verificationPlatform

    const hasUserConsented = ref(false)

    const biometricConsentRequired = ref(false)
    const userIpLocationCountry = ref('')

    onMounted(async () => {
      isLoading.value = true
      const ipAddress = await getExternalIP()
      const result = await getIsBiometricConsentRequired.exec({ ipHeader: ipAddress })
      biometricConsentRequired.value = result?.isBiometricConsentRequired ?? false
      userIpLocationCountry.value = result?.countryCode ?? ''
      isLoading.value = false

      // __SEGMENT__EVENT
      analyticsStore.track({
        event: SEGMENT_EVENTS.IDENTITY_VERIFICATION_STARTED,
        traits: {
          biometricPlatform: verificationPlatform,
          location: 'sendMoney',
        },
      })
    })

    const isContinueDisabled = computed(() => {
      if (isLoading.value) return true
      if (biometricConsentRequired.value && !hasUserConsented.value) return true
      return false
    })

    const startVerification = () => {
      // __SEGMENT__EVENT
      analyticsStore.track({
        event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_STARTED,
        traits: {
          biometricPlatform: verificationPlatform,
          externalSessionId: sendMoneyStore.verificationApplicationId,
          location: 'sendMoney',
        },
      })
      // __SEGMENT__EVENT
      if (hasUserConsented.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.BIOMETRIC_CONSENT_PROVIDED,
          traits: {
            biometricPlatform: verificationPlatform,
            location: 'sendMoney',
            country: userIpLocationCountry.value,
          },
        })
      }
      router.push(verificationPlatform)
    }

    const showCancel = ref(false)

    return {
      startVerification,
      showCancel,
      $t,
      pageTitle,
      hasUserConsented,
      privacyPolicyLink,
      verificationPlatform,
      isLoading,
      isContinueDisabled,
      biometricConsentRequired,
    }
  },
}
</script>

<style scoped>
.ol {
  color: #313f5b;
  @apply text-left;
}

::v-deep ol li:before {
  color: var(--theme-color-primary, theme('colors.blue.default'));
}

.card-footer {
  button {
    width: 100% !important;
  }
}

.biometric-consent {
  ::v-deep .input-checkbox-virtual-checkbox {
    @apply flex-shrink-0 m-0;
  }
}
</style>
